export function useAppBar () {
  const state = useState<{ status: 'expanded' | 'shrunk' | 'hidden', leftDrawer: boolean, rail: boolean }>('appBar', () => {
    return {
      status: 'hidden',
      leftDrawer: true,
      rail: true
    }
  })

  return {
    ...toRefs(state.value)
  }
}
