<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'cancel'): void, (e: 'confirm'): void }>()

const dialog = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="100%"
    max-width="360px"
    style="touch-action: manipulation;"
  >
    <v-card>
      <v-toolbar color="#fff">
        <v-toolbar-title text="WeChat Account of Developer" />
      </v-toolbar>
      <v-card-text style="min-height: 80px;">
        <v-sheet class="text-center">
          <v-avatar size="120">
            <img
              src="/image/avatar_developer_400x400.jpg"
              style="object-fit: cover;"
              width="100%"
            >
          </v-avatar>
          <p class="text-subtitle-1">
            nobuhiro77
          </p>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
